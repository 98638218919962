import { lazy } from 'react';

export const useLazyComponents = () => {
  // LOGIN - SIGNUP - REGISTERS
  const LoginAndSignUp = lazy(() => import('Pages/LoginAndSignUp'));
  const ConfirmEmailPage = lazy(() => import('Pages/EmailConfirm'));
  const SquadMemberProfileRegisterPage = lazy(() =>
    import('Pages/ProfileRegister'),
  );
  const SquadOwnerRegister = lazy(() => import('Pages/SquadOwnerRegister'));
  const ResetPasswordPage = lazy(() => import('Pages/ResetPassword'));
  const FillProfileDataPage = lazy(() => import('Pages/FillProfileData'));
  const SendEmailResetPasswordPage = lazy(() =>
    import('Pages/SendEmailResetPassword'),
  );
  const SquadOwnerAlternativeSignIn = lazy(() =>
    import('Pages/SquadOwnerAlternativeSignIn').then(module => ({
      default: module.SquadOwnerAlternativeSignIn,
    })),
  );
  const AdminSignUpPage = lazy(() => import('Pages/AdminSignUp'));
  const Page404 = lazy(() => import('Pages/404'));
  const PageForbidden = lazy(() => import('Pages/Forbidden'));

  // PROFILES
  const ProfileSquadMember = lazy(() => import('Pages/SquadMemberProfile'));
  const ProfileMemberMainView = lazy(() =>
    import('AppV2/pages/UserProfile/SquadMember/MainView').then(module => ({
      default: module.ProfileMemberMainView,
    })),
  );
  const ProfileMemberMainViewProvider = lazy(() =>
    import('AppV2/pages/UserProfile/SquadMember/MainView/provider').then(
      module => ({
        default: module.ProfileMemberViewProvider,
      }),
    ),
  );
  const ProfileMemberUserRolesView = lazy(() =>
    import('AppV2/pages/UserProfile/SquadMember/UserRolesView').then(
      module => ({
        default: module.ProfileMemberUserRolesView,
      }),
    ),
  );

  const ProfileSquadOwner = lazy(() => import('Pages/SquadOwnerProfile'));
  const ProfileSquadMemberOpenView = lazy(() =>
    import('Pages/SquadMemberProfileOpenView'),
  );
  const SquadMemberProfileHiringProcessProvider = lazy(() =>
    import(
      'AppV2/pages/UserProfile/SquadMember/HiringProcessView/provider'
    ).then(module => ({
      default: module.ProfileMemberHiringProcessViewProvider,
    })),
  );
  const SquadMemberProfileHiringProcessView = lazy(() =>
    import('AppV2/pages/UserProfile/SquadMember/HiringProcessView').then(
      module => ({
        default: module.ProfileMemberHiringProcessView,
      }),
    ),
  );
  const SquadMemberProfileDiscoveryView = lazy(() =>
    import('AppV2/pages/UserProfile/SquadMember/DiscoveryView').then(
      module => ({
        default: module.SquadMemberProfileDiscoveryView,
      }),
    ),
  );
  const ProfileSquadOwnerOpenView = lazy(() =>
    import('Pages/SquadOwnerProfileOpenView'),
  );
  const ProfileSquadMemberExportViewProvider = lazy(() =>
    import('AppV2/pages/UserProfile/SquadMember/ExportView/provider').then(
      module => ({
        default: module.ProfileMemberViewProvider,
      }),
    ),
  );
  const ProfileSquadMemberExportView = lazy(() =>
    import('AppV2/pages/UserProfile/SquadMember/ExportView').then(module => ({
      default: module.ProfileMemberMainDesktopView,
    })),
  );

  // PROJECT BRIEF
  const NewJourney = lazy(() =>
    import('Pages/ProjectBrief/NewJourney').then(module => ({
      default: module.NewJourney,
    })),
  );
  const CampaignFormsThanksPage = lazy(() =>
    import('Pages/ProjectBrief/ThanksPage/campaignThanksPage').then(module => ({
      default: module.CampaignFormsThanksPage,
    })),
  );
  const ProjectBriefSelectionPage = lazy(() =>
    import('Pages/ProjectBrief/Selection'),
  );
  const ProjectBriefForms = lazy(() => import('Pages/ProjectBrief/Forms'));
  const FormsThanksPage = lazy(() =>
    import('Pages/ProjectBrief/ThanksPage/formsThanks'),
  );
  const MatchPage = lazy(() => import('Pages/ProjectBrief/Match'));
  const ProjectBriefOnboardingPage = lazy(() =>
    import('Pages/ProjectBrief/Onboarding'),
  );
  const MatchSearchingPage = lazy(() =>
    import('Pages/ProjectBrief/MatchSearching'),
  );
  const ProjectBriefView = lazy(() => import('Pages/ProjectBriefView'));
  const BriefSquadThanksPage = lazy(() =>
    import('Pages/ProjectBrief/ThanksPage/briefSquadThanks'),
  );
  const BlockedPage = lazy(() => import('Pages/ProjectBrief/BlockedPage'));
  const UpsellBriefingThankYouPage = lazy(() =>
    import('Pages/ProjectBrief/ThanksPage/upsellThanksPage').then(module => ({
      default: module.UpsellBriefingThankYouPage,
    })),
  );
  const ServicePage = lazy(() => import('Pages/Service'));
  const MaintenancePage = lazy(() => import('Pages/Maintenance'));
  const CreateComponent = lazy(() => import('Pages'));
  const ProposalBudgetView = lazy(() =>
    import('Pages/BudgetInHouseView/ProposalBudget'),
  );
  const FirstMemberBudgetSendView = lazy(() =>
    import('Pages/BudgetInHouseView/FirstMemberBudgetSend'),
  );
  const BudgetThankYouPage = lazy(() =>
    import('Pages/BudgetInHouseView/ThankYouPage'),
  );

  // JOBS
  const JobsOpeningsProvider = lazy(() =>
    import('AppV2/pages/Opportunities/JobOpeningsView/provider').then(
      module => ({
        default: module.JobOpeningsProvider,
      }),
    ),
  );
  const JobsOpeningsView = lazy(() =>
    import('AppV2/pages/Opportunities/JobOpeningsView').then(module => ({
      default: module.JobsOpeningsView,
    })),
  );
  const JobDetailsView = lazy(() =>
    import('AppV2/pages/Opportunities/JobDetailsView').then(module => ({
      default: module.JobDetailsView,
    })),
  );
  const JobDetailsProvider = lazy(() =>
    import('AppV2/pages/Opportunities/JobDetailsView/provider').then(
      module => ({
        default: module.JobDetailsProvider,
      }),
    ),
  );

  // CREDITS
  const CreditsPaymentsPage = lazy(() =>
    import('AppV2/pages/CreditsPayments').then(module => ({
      default: module.CreditsPaymentsPage,
    })),
  );
  const CreditsPaymentsProvider = lazy(() =>
    import('AppV2/pages/CreditsPayments/provider').then(module => ({
      default: module.CreditsPaymentsProvider,
    })),
  );

  const CreditsPage = lazy(() =>
    import('Pages/Credits').then(module => ({ default: module.CreditsPage })),
  );
  const CreditsProvider = lazy(() =>
    import('Contexts/CreditsContext').then(module => ({
      default: module.CreditsProvider,
    })),
  );
  const CreditsThankYouPage = lazy(() =>
    import('Pages/Credits/thankYouPage').then(module => ({
      default: module.CreditsThankYouPage,
    })),
  );
  const AllocateHourPage = lazy(() =>
    import('Pages/AllocateHours').then(module => ({
      default: module.AllocateHourPage,
    })),
  );
  const AllocateHoursProvider = lazy(() =>
    import('Contexts/AllocateHoursContext').then(module => ({
      default: module.AllocateHoursProvider,
    })),
  );
  const AllocateHoursThankYouPage = lazy(() =>
    import('Pages/AllocateHours/thankYouPage').then(module => ({
      default: module.AllocateHoursThankYouPage,
    })),
  );
  const OwnerExtractsPage = lazy(() =>
    import('Pages/Extracts').then(module => ({
      default: module.OwnerExtractsPage,
    })),
  );
  const OwnerExtractProvider = lazy(() =>
    import('Contexts/OwnerExtractContext').then(module => ({
      default: module.OwnerExtractProvider,
    })),
  );

  // DASHBOARD
  const DashboardPage = lazy(() =>
    import('AppV2/pages/Dashboard').then(module => ({
      default: module.DashboardPage,
    })),
  );

  // WORKSPACE
  const WorkspacePage = lazy(() =>
    import('AppV2/pages/Workspace/MainView').then(module => ({
      default: module.WorkspacePage,
    })),
  );
  const WorkspaceProvider = lazy(() =>
    import('AppV2/pages/Workspace/MainView/provider').then(module => ({
      default: module.WorkspaceProvider,
    })),
  );

  const WorkspaceInvitePage = lazy(() =>
    import('AppV2/pages/Workspace/InviteView').then(module => ({
      default: module.WorkspaceInviteViewPage,
    })),
  );
  const WorkspaceInviteProvider = lazy(() =>
    import('AppV2/pages/Workspace/InviteView/provider').then(module => ({
      default: module.WorkspaceInviteViewProvider,
    })),
  );

  // MY PROJECTS
  const MyProjectsView = lazy(() =>
    import('AppV2/pages/MyProjects/MyProjectsView').then(module => ({
      default: module.MyProjectsView,
    })),
  );
  const ProjectView = lazy(() =>
    import('AppV2/pages/MyProjects/ProjectView').then(module => ({
      default: module.ProjectView,
    })),
  );

  // MY FREELANCERS
  const MyFreelancersView = lazy(() =>
    import('AppV2/pages/MyFreelancers/MyFreelancersView').then(module => ({
      default: module.MyFreelancersView,
    })),
  );
  const FreelancerView = lazy(() =>
    import('AppV2/pages/MyFreelancers/FreelancerView').then(module => ({
      default: module.FreelancerView,
    })),
  );
  const SaveProjectProvider = lazy(() =>
    import('Contexts/SaveProjectContext').then(module => ({
      default: module.SaveProjectProvider,
    })),
  );

  // MATCH MAKING
  const MatchMakingProvider = lazy(() =>
    import('AppV2/pages/MatchMaking/MainView/provider/index').then(module => ({
      default: module.MatchMakingProvider,
    })),
  );
  const MatchMakingPage = lazy(() =>
    import('AppV2/pages/MatchMaking/MainView').then(module => ({
      default: module.MatchMakingPage,
    })),
  );
  const MatchMakingCreatePersonaProvider = lazy(() =>
    import('AppV2/pages/MatchMaking/CreatePersonaView/provider').then(
      module => ({
        default: module.MatchMakingCreatePersonaProvider,
      }),
    ),
  );
  const MatchMakingCreatePersonaViewPage = lazy(() =>
    import('AppV2/pages/MatchMaking/CreatePersonaView').then(module => ({
      default: module.MatchMakingCreatePersonaView,
    })),
  );
  const MatchMakingInternalViewProvider = lazy(() =>
    import('AppV2/pages/MatchMaking/InternalView/provider/index').then(
      module => ({
        default: module.MatchMakingInternalViewProvider,
      }),
    ),
  );
  const MatchMakingInternalViewPage = lazy(() =>
    import('AppV2/pages/MatchMaking/InternalView').then(module => ({
      default: module.MatchMakingIntervalViewPage,
    })),
  );
  const MatchMakingResultsViewProvider = lazy(() =>
    import('AppV2/pages/MatchMaking/ResultsView/provider/index').then(
      module => ({
        default: module.MatchMakingResultsViewProvider,
      }),
    ),
  );
  const MatchMakingResultsViewPage = lazy(() =>
    import('AppV2/pages/MatchMaking/ResultsView').then(module => ({
      default: module.MatchMakingResultsViewPage,
    })),
  );
  const OpenMatchMakingResultsViewProvider = lazy(() =>
    import('AppV2/pages/MatchMaking/OpenResultsView/provider/index').then(
      module => ({
        default: module.OpenMatchMakingResultsProvider,
      }),
    ),
  );
  const OpenMatchMakingResultsViewPage = lazy(() =>
    import('AppV2/pages/MatchMaking/OpenResultsView').then(module => ({
      default: module.OpenMatchMakingResultsPage,
    })),
  );
  const MatchMakingInviteViewProvider = lazy(() =>
    import('AppV2/pages/MatchMaking/InviteView/provider/index').then(
      module => ({
        default: module.MatchMakingInviteViewProvider,
      }),
    ),
  );
  const MatchMakingInviteViewPage = lazy(() =>
    import('AppV2/pages/MatchMaking/InviteView').then(module => ({
      default: module.MatchmakingInviteViewPage,
    })),
  );
  const MatchmakingThankyouPageView = lazy(() =>
    import('AppV2/pages/MatchMaking/ThankyouPageView').then(module => ({
      default: module.MatchmakingThankyouPageView,
    })),
  );
  const ResultsPersonaView = lazy(() =>
    import('AppV2/pages/MatchMaking/ResultsPersonaView').then(module => ({
      default: module.ResultsPersonaView,
    })),
  );

  // TASKS ASSIGNMENTS
  const TaskAssignmentsProvider = lazy(() =>
    import('AppV2/pages/TaskAssignments/TaskPanelView/provider').then(
      module => ({
        default: module.TaskAssignmentsProvider,
      }),
    ),
  );
  const KanbanView = lazy(() =>
    import('AppV2/pages/TaskAssignments/KanbanView').then(module => ({
      default: module.KanbanView,
    })),
  );
  const TaskPanelView = lazy(() =>
    import('AppV2/pages/TaskAssignments/TaskPanelView').then(module => ({
      default: module.TaskPanelView,
    })),
  );
  const KanbanViewProvider = lazy(() =>
    import('AppV2/pages/TaskAssignments/KanbanView/provider').then(module => ({
      default: module.KanbanViewProvider,
    })),
  );
  const TimesheetView = lazy(() =>
    import('AppV2/pages/TaskAssignments/TimesheetView').then(module => ({
      default: module.TimesheetView,
    })),
  );

  // MEMBER - TESTS - PROFILE INTENSIFIER
  const ProfileIntensifiersProvider = lazy(() =>
    import('AppV2/pages/UserTests/ProfileIntensifiers/provider').then(
      module => ({
        default: module.ProfileIntensifierProvider,
      }),
    ),
  );
  const ProfileIntensifiersPage = lazy(() =>
    import('AppV2/pages/UserTests/ProfileIntensifiers').then(module => ({
      default: module.ProfileIntensifiersPage,
    })),
  );

  // OPEN PROFILE INTESIFIERS
  const OpenProfileIntensifiersProvider = lazy(() =>
    import('AppV2/pages/UserTests/OpenProfileIntensifiers/provider').then(
      module => ({
        default: module.OpenProfileIntensifierProvider,
      }),
    ),
  );
  const OpenProfileIntensifiersPage = lazy(() =>
    import('AppV2/pages/UserTests/OpenProfileIntensifiers').then(module => ({
      default: module.OpenProfileIntensifiersPage,
    })),
  );
  const OpenTestsThankyouPage = lazy(() =>
    import('AppV2/pages/UserTests/OpenThankyouPage').then(module => ({
      default: module.OpenTestsThankyouPage,
    })),
  );

  // MEMBER - TESTS
  const UserTestResumesPage = lazy(() =>
    import('AppV2/pages/UserTests/ProfileTestResumes').then(module => ({
      default: module.UserTestResumesPage,
    })),
  );
  const SquadMemberDISCTestsPage = lazy(() =>
    import('Pages/SquadMemberQuizTests/TestsSelection'),
  );
  const TestPersonalityQuestionsPage = lazy(() =>
    import('Pages/SquadMemberQuizTests/TestQuestions/Personality'),
  );
  const TestSoftSkillsResultPage = lazy(() =>
    import('Pages/SquadMemberQuizTests/TestResult/SoftSkills'),
  );
  const TestPersonalityResultPage = lazy(() =>
    import('Pages/SquadMemberQuizTests/TestResult/Personality'),
  );
  const TestQuestionSkillsPage = lazy(() =>
    import('Pages/SquadMemberQuizTests/TestQuestions/Skills'),
  );
  const TestCompletedSoftSkills = lazy(() =>
    import('Pages/SquadMemberQuizTests/TestCompleted/SoftSkills'),
  );
  const TestCompletedPersonality = lazy(() =>
    import('Pages/SquadMemberQuizTests/TestCompleted/Personality'),
  );
  const SharedPersonalityTestResults = lazy(() =>
    import('Pages/SquadMemberQuizTests/SharedResults/Personality'),
  );
  const SharedCultureTestResults = lazy(() =>
    import('Pages/SquadMemberQuizTests/SharedResults/OrganizationalCulture'),
  );
  const SharedSkillsTestResults = lazy(() =>
    import('Pages/SquadMemberQuizTests/SharedResults/SoftSkills'),
  );

  // OWNER OCAI_TESTS
  const OCAIQuizPageOwnerView = lazy(() =>
    import('AppV2/pages/UserTests/OCAI/ViewSquadOwner/QuizPage').then(
      module => ({
        default: module.OCAIQuizPageOwnerView,
      }),
    ),
  );
  const OCAIProviderOwnerView = lazy(() =>
    import('AppV2/pages/UserTests/OCAI/ViewSquadOwner/QuizPage/provider').then(
      module => ({
        default: module.OCAIProviderOwnerView,
      }),
    ),
  );

  const OCAIComplementaryQuizPageOwnerView = lazy(() =>
    import(
      'AppV2/pages/UserTests/OCAIComplementary/ViewSquadOwner/QuizPage'
    ).then(module => ({
      default: module.OCAIComplementaryQuizPageOwnerView,
    })),
  );
  const OCAIComplementaryProviderOwnerView = lazy(() =>
    import(
      'AppV2/pages/UserTests/OCAIComplementary/ViewSquadOwner/QuizPage/provider'
    ).then(module => ({
      default: module.OCAIComplementaryProviderOwnerView,
    })),
  );
  const OCAIComplementaryProviderResultsPageOwnerView = lazy(() =>
    import(
      'AppV2/pages/UserTests/OCAIComplementary/ViewSquadOwner/ResultsPage/provider'
    ).then(module => ({
      default: module.OCAIComplementaryProviderResultsOwnerView,
    })),
  );
  const OCAIComplementaryResultsPageOwnerView = lazy(() =>
    import(
      'AppV2/pages/UserTests/OCAIComplementary/ViewSquadOwner/ResultsPage'
    ).then(module => ({
      default: module.OCAIComplemetaryResultsPageOwnerView,
    })),
  );

  const OCAIQuizPageMemberView = lazy(() =>
    import('AppV2/pages/UserTests/OCAI/ViewSquadMember/QuizPage').then(
      module => ({
        default: module.OCAIQuizPageMemberView,
      }),
    ),
  );
  const OCAIProviderMemberView = lazy(() =>
    import('AppV2/pages/UserTests/OCAI/ViewSquadMember/QuizPage/provider').then(
      module => ({
        default: module.OCAIProviderMemberView,
      }),
    ),
  );
  const OCAIThankYouPageMemberView = lazy(() =>
    import('AppV2/pages/UserTests/OCAI/ViewSquadMember/ThankYouPage').then(
      module => ({
        default: module.OCAIThankYouPageMemberView,
      }),
    ),
  );
  const OCAIResultsPageMemberView = lazy(() =>
    import('AppV2/pages/UserTests/OCAI/ViewSquadMember/ResultsPage').then(
      module => ({
        default: module.OCAIResultsPageMemberView,
      }),
    ),
  );

  // DISC
  const DISCViewProvider = lazy(() =>
    import('AppV2/pages/UserTests/DISC/provider').then(module => ({
      default: module.DISCViewProvider,
    })),
  );

  const DISCQuizView = lazy(() =>
    import('AppV2/pages/UserTests/DISC').then(module => ({
      default: module.DISCQuizPage,
    })),
  );

  // MOVING MOTIVATORS
  const MovingMotivatorsProvider = lazy(() =>
    import('AppV2/pages/UserTests/MovingMotivators/TestView/provider').then(
      module => ({
        default: module.MovingMotivatorsProvider,
      }),
    ),
  );

  const MovingMotivatorsPage = lazy(() =>
    import('AppV2/pages/UserTests/MovingMotivators/TestView').then(module => ({
      default: module.MovingMotivatorsPage,
    })),
  );

  const MovingMotivatorsResultsProvider = lazy(() =>
    import('AppV2/pages/UserTests/MovingMotivators/ResultsView/provider').then(
      module => ({
        default: module.MovingMotivatorsResultsProvider,
      }),
    ),
  );

  const MovingMotivatorsResultsPage = lazy(() =>
    import('AppV2/pages/UserTests/MovingMotivators/ResultsView').then(
      module => ({
        default: module.MovingMotivatorsResultsPage,
      }),
    ),
  );

  const MovingMotivatorsSharedResultsProvider = lazy(() =>
    import(
      'AppV2/pages/UserTests/MovingMotivators/SharedResultsView/provider'
    ).then(module => ({
      default: module.MovingMotivatorsSharedProvider,
    })),
  );

  const MovingMotivatorsSharedResultsPage = lazy(() =>
    import('AppV2/pages/UserTests/MovingMotivators/SharedResultsView').then(
      module => ({
        default: module.MovingMotivatorsSharedResultsPage,
      }),
    ),
  );

  // CMS
  const CMSForkPage = lazy(() => import('Pages/CMS/Fork'));
  const AnalyticsDataStudioPage = lazy(() =>
    import('Pages/CMS/AnalyticsDataStudio'),
  );
  const SearchProfessionalsPage = lazy(() => import('Pages/CMS/Professionals'));
  const SearchProjectsPage = lazy(() => import('Pages/CMS/Projects'));
  const SearchOwnersPage = lazy(() => import('Pages/CMS/Owners'));
  const EmailCheckedPage = lazy(() => import('Pages/EmailChecked'));
  const SquadListProvider = lazy(() =>
    import('Contexts/SquadListContext').then(module => ({
      default: module.SquadListProvider,
    })),
  );
  const SuggestionCMSPage = lazy(() =>
    import('Pages/CMS/Suggestion').then(module => ({
      default: module.SuggestionCMSPage,
    })),
  );
  const CmsSquadSuggestionProvider = lazy(() =>
    import('Contexts/CMSSquadSuggestion').then(module => ({
      default: module.CmsSquadSuggestionProvider,
    })),
  );
  const SquadSelectionPage = lazy(() => import('Pages/CMS/SquadSelection'));
  const MatchRatingPage = lazy(() =>
    import('AppV2/pages/CMS/MatchRating').then(module => ({
      default: module.MatchRatingPage,
    })),
  );

  // SUPPORT
  const SupportMemberViewProvider = lazy(() =>
    import('Contexts/SupportMemberViewContext').then(module => ({
      default: module.SupportMemberViewProvider,
    })),
  );
  const SupportMemberViewPage = lazy(() =>
    import('Pages/SupportMemberView').then(module => ({
      default: module.SupportMemberViewPage,
    })),
  );
  const SupportOwnerViewProvider = lazy(() =>
    import('Contexts/SupportOwnerViewContext').then(module => ({
      default: module.SupportOwnerViewProvider,
    })),
  );
  const SupportOwnerViewPage = lazy(() =>
    import('Pages/SupportOwnerView').then(module => ({
      default: module.SupportOwnerViewPage,
    })),
  );

  // SQUAD MEMBER APPLICATIONS
  const ApplicationsMemberProvider = lazy(() =>
    import('AppV2/pages/Applications/provider').then(module => ({
      default: module.ApplicationsProvider,
    })),
  );
  const ApplicationsMemberPage = lazy(() =>
    import('AppV2/pages/Applications').then(module => ({
      default: module.ApplicationsMemberPage,
    })),
  );

  // RECOMMENDATIONS
  const UsersRecommendationsPage = lazy(() =>
    import('AppV2/pages/Recommendations/UserRecommendationsView').then(
      module => ({
        default: module.UserRecommendationsView,
      }),
    ),
  );

  const RecommenderEvaluationPage = lazy(() =>
    import('AppV2/pages/Recommendations/RecommenderEvaluationView').then(
      module => ({
        default: module.RecommenderEvaluationView,
      }),
    ),
  );

  // ASSESSMENTS
  const AssessmentsProvider = lazy(() =>
    import('AppV2/pages/Assessments/MainView/provider').then(module => ({
      default: module.AssessmentsProvider,
    })),
  );

  const AssessmentsPage = lazy(() =>
    import('AppV2/pages/Assessments/MainView').then(module => ({
      default: module.AssessmentsPage,
    })),
  );

  const AssessmentsInviteViewProvider = lazy(() =>
    import('AppV2/pages/Assessments/InviteView/provider').then(module => ({
      default: module.AssessmentsInviteProvider,
    })),
  );

  const AssessmentsInviteView = lazy(() =>
    import('AppV2/pages/Assessments/InviteView').then(module => ({
      default: module.AssessmentsInviteView,
    })),
  );

  const AssessmentsInviteThankyouPage = lazy(() =>
    import('AppV2/pages/Assessments/ThankyouPageView').then(module => ({
      default: module.AssessmentInviteThankyouPageView,
    })),
  );

  // HUNTER HERO
  const HunterHeroPersonaFormPage = lazy(() =>
    import('AppV2/pages/HunterHero/PersonaForm').then(module => ({
      default: module.PersonaFormPage,
    })),
  );

  const HunterHeroPersonaPage = lazy(() =>
    import('AppV2/pages/HunterHero/PersonaView').then(module => ({
      default: module.ResultsPersonaView,
    })),
  );

  const HunterHeroCompanyOCAIComplementaryFormProvider = lazy(() =>
    import('AppV2/pages/HunterHero/OCAIComplementary/QuizPage/provider').then(
      module => ({
        default: module.OCAIComplementaryProviderOwnerView,
      }),
    ),
  );

  const HunterHeroCompanyOCAIComplementaryFormPage = lazy(() =>
    import('AppV2/pages/HunterHero/OCAIComplementary/QuizPage').then(
      module => ({
        default: module.OCAIComplementaryQuizPageOwnerView,
      }),
    ),
  );

  const HunterHeroCompanyOCAIResultsProvider = lazy(() =>
    import(
      'AppV2/pages/HunterHero/OCAIComplementary/ResultsPage/provider'
    ).then(module => ({
      default: module.OCAIComplementaryProviderResultsOwnerView,
    })),
  );

  const HunterHeroCompanyOCAIResultsPage = lazy(() =>
    import('AppV2/pages/HunterHero/OCAIComplementary/ResultsPage').then(
      module => ({
        default: module.OCAIComplemetaryResultsPageOwnerView,
      }),
    ),
  );

  return {
    LoginAndSignUp,
    ConfirmEmailPage,
    SquadMemberProfileRegisterPage,
    ResetPasswordPage,
    FillProfileDataPage,
    SquadMemberDISCTestsPage,
    TestPersonalityQuestionsPage,
    TestSoftSkillsResultPage,
    TestPersonalityResultPage,
    TestQuestionSkillsPage,
    TestCompletedSoftSkills,
    TestCompletedPersonality,
    Page404,
    SendEmailResetPasswordPage,
    ProfileSquadMember,
    SharedPersonalityTestResults,
    SharedCultureTestResults,
    SharedSkillsTestResults,
    SquadOwnerRegister,
    ProjectBriefSelectionPage,
    ProjectBriefForms,
    FormsThanksPage,
    MatchPage,
    ProjectBriefOnboardingPage,
    MatchSearchingPage,
    ProfileSquadOwner,
    ProjectBriefView,
    BriefSquadThanksPage,
    BlockedPage,
    SearchProfessionalsPage,
    SearchProjectsPage,
    PageForbidden,
    ProfileSquadMemberOpenView,
    ServicePage,
    MaintenancePage,
    AdminSignUpPage,
    CMSForkPage,
    SquadSelectionPage,
    MatchRatingPage,
    ProfileSquadOwnerOpenView,
    CreateComponent,
    ProposalBudgetView,
    FirstMemberBudgetSendView,
    BudgetThankYouPage,
    AnalyticsDataStudioPage,
    SquadOwnerAlternativeSignIn,
    SearchOwnersPage,
    EmailCheckedPage,
    SquadListProvider,
    SupportMemberViewProvider,
    SupportMemberViewPage,
    SupportOwnerViewProvider,
    SupportOwnerViewPage,
    CreditsPage,
    CreditsProvider,
    CreditsThankYouPage,
    AllocateHourPage,
    AllocateHoursProvider,
    AllocateHoursThankYouPage,
    OwnerExtractsPage,
    OwnerExtractProvider,
    UpsellBriefingThankYouPage,
    DashboardPage,
    WorkspacePage,
    WorkspaceProvider,
    WorkspaceInvitePage,
    WorkspaceInviteProvider,
    MyProjectsView,
    ProjectView,
    MyFreelancersView,
    FreelancerView,
    TaskAssignmentsProvider,
    SquadMemberProfileDiscoveryView,
    CampaignFormsThanksPage,
    SuggestionCMSPage,
    CmsSquadSuggestionProvider,
    NewJourney,
    SaveProjectProvider,
    KanbanView,
    TaskPanelView,
    KanbanViewProvider,
    TimesheetView,
    SquadMemberProfileHiringProcessProvider,
    SquadMemberProfileHiringProcessView,
    ProfileIntensifiersProvider,
    ProfileIntensifiersPage,
    OpenProfileIntensifiersPage,
    OpenProfileIntensifiersProvider,
    OpenTestsThankyouPage,
    UserTestResumesPage,
    OCAIQuizPageOwnerView,
    OCAIProviderOwnerView,
    OCAIComplementaryQuizPageOwnerView,
    OCAIComplementaryProviderOwnerView,
    OCAIComplementaryProviderResultsPageOwnerView,
    OCAIComplementaryResultsPageOwnerView,
    OCAIQuizPageMemberView,
    OCAIProviderMemberView,
    OCAIThankYouPageMemberView,
    OCAIResultsPageMemberView,
    MovingMotivatorsPage,
    MovingMotivatorsProvider,
    MovingMotivatorsResultsPage,
    MovingMotivatorsResultsProvider,
    MovingMotivatorsSharedResultsProvider,
    MovingMotivatorsSharedResultsPage,
    JobsOpeningsProvider,
    JobsOpeningsView,
    JobDetailsView,
    JobDetailsProvider,
    CreditsPaymentsPage,
    CreditsPaymentsProvider,
    MatchMakingProvider,
    MatchMakingPage,
    MatchMakingCreatePersonaProvider,
    MatchMakingCreatePersonaViewPage,
    MatchMakingInternalViewProvider,
    MatchMakingInternalViewPage,
    MatchMakingResultsViewProvider,
    MatchMakingResultsViewPage,
    OpenMatchMakingResultsViewProvider,
    OpenMatchMakingResultsViewPage,
    MatchMakingInviteViewProvider,
    MatchMakingInviteViewPage,
    MatchmakingThankyouPageView,
    ResultsPersonaView,
    ProfileMemberMainView,
    ProfileMemberMainViewProvider,
    ProfileMemberUserRolesView,
    ProfileSquadMemberExportView,
    ProfileSquadMemberExportViewProvider,
    DISCViewProvider,
    DISCQuizView,
    ApplicationsMemberPage,
    ApplicationsMemberProvider,
    UsersRecommendationsPage,
    RecommenderEvaluationPage,
    AssessmentsProvider,
    AssessmentsPage,
    AssessmentsInviteViewProvider,
    AssessmentsInviteView,
    AssessmentsInviteThankyouPage,
    HunterHeroPersonaFormPage,
    HunterHeroPersonaPage,
    HunterHeroCompanyOCAIComplementaryFormProvider,
    HunterHeroCompanyOCAIComplementaryFormPage,
    HunterHeroCompanyOCAIResultsProvider,
    HunterHeroCompanyOCAIResultsPage,
  };
};
