export const TEMPLATES_KEY_CACHE_QUERY = 'TEMPLATE_KEY';

export enum TemplatesKeysEnum {
  GROWYX = 'growyx',
  HUNTERS_99 = 'hunterhero',
}

export type TemplatesConfig = {
  templateKey: TemplatesKeysEnum;
  head: {
    title: string;
    description: string;
    favIcon: string;
    favIconAppleTouch: string;
  };
};
