import { colors } from 'AppV2/styles/colors';
import styled from 'styled-components';

export type AlertType = 'success' | 'error' | 'warning';
interface Props {
  styleType: AlertType;
}

const COLOR = {
  success: colors.primary,
  error: colors.red500,
  warning: colors.yellow,
};

const SHADOW = {
  success: colors.alertSuccessShadow,
  error: colors.alertErrorShadow,
  warning: colors.alertErrorShadow,
};

const TIMER_BAR_BG = {
  success: colors.linearAlertBar,
  // success: `${colors.primary}80`,
  error: colors.red500,
  warning: colors.yellow,
};

export const AlertContainer = styled.div<Props>`
  position: fixed;
  z-index: 999999;
  bottom: 32px;
  right: 32px;

  width: calc(100% - 16px);
  max-width: 417px;
  padding: 24px 24px 36px;

  background: ${colors.background100};
  border: 2px solid ${({ styleType }) => COLOR[styleType]};
  box-shadow: ${({ styleType }) => SHADOW[styleType]} !important;
  border-radius: 12px;

  transform: translateY(300%);

  @keyframes open {
    to {
      transform: translateY(0%);
    }
  }
  @keyframes close {
    from {
      transform: translateY(0%);
    }
    to {
      transform: translateY(300%);
    }
  }

  .timebar-container {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 10px;
    left: 0px;
  }
  @media screen and (max-width: 480px) {
    right: 8px;
  }
`;

export const TimerBar = styled.div<Props>`
  position: absolute;
  bottom: 0px;
  left: 0px;

  width: 100%;
  height: 100%;

  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  background: ${({ styleType }) => TIMER_BAR_BG[styleType]};

  transition: 0.3s ease;
  @keyframes timeBarLoading {
    from {
      width: 100%;
    }
    20% {
      border-bottom-right-radius: 0px;
    }
    to {
      border-bottom-right-radius: 0px;
      width: 0%;
    }
  }
`;

export const HeaderContent = styled.div<Props>`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  .icon-content {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ styleType }) => COLOR[styleType]}10;
    border-radius: 8px;
    margin-right: 12px;
    width: 28px;
    height: 28px;
    min-width: 28px;
    svg {
      color: ${({ styleType }) => COLOR[styleType]};
      width: 16px;
      height: 16px;
      min-width: 16px;
    }
  }

  h3 {
    margin: 0px;
    color: ${({ styleType }) => COLOR[styleType]};
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
  }
`;

export const ButtonCloseAlert = styled.button<Props>`
  background-color: transparent;
  color: ${({ styleType }) => COLOR[styleType]};
  width: 22px;
  height: 22px;
  min-width: 22px;
  margin-left: auto;
`;

export const Description = styled.p`
  margin: 0px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${colors.darkText};
  white-space: break-spaces;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const SupportRedirect = styled.button`
  display: block;
  padding: 0px;
  margin: 12px 0px 0px;
  margin-left: auto;
  color: ${colors.primary};
  font-size: 14px;

  transition: 0.3s;
  :hover {
    opacity: 0.9;
    text-decoration: underline;
  }
`;
