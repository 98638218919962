/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useLazyComponents } from './useLazyComponents';
import { LoadingPage } from 'AppV2/components/LoadingPage';
import LoginAndSignUp from 'Pages/LoginAndSignUp';

const Routes = ({ userRole, workspaceId }) => {
  const LazyComponents = useLazyComponents();
  return (
    <Suspense fallback={<LoadingPage.Lazy />}>
      <Switch>
        <Route
          exact
          path={['/', '/login', '/signup']}
          component={() => <LoginAndSignUp />}
        />
        <Route
          exact
          path="/matchmaking-signup"
          component={() => (
            <LazyComponents.SquadOwnerAlternativeSignIn view="matchmaking_trial" />
          )}
        />
        <Route
          exact
          path="/assessment-signup"
          component={() => (
            <LazyComponents.SquadOwnerAlternativeSignIn view="assessment" />
          )}
        />
        <Route
          path="/sales-signup/:campaign?"
          component={() => (
            <LazyComponents.SquadOwnerAlternativeSignIn view={'sales'} />
          )}
        />

        <Route
          path="/squadMember-register"
          component={() => <LazyComponents.SquadMemberProfileRegisterPage />}
        />

        <Route
          path="/service"
          component={() => <LazyComponents.ServicePage />}
        />
        <Route
          path="/confirm-email"
          component={() => <LazyComponents.ConfirmEmailPage />}
        />
        <Route
          path="/email-checked"
          component={() => <LazyComponents.EmailCheckedPage />}
        />
        <Route
          path="/send-email-reset-password"
          component={() => <LazyComponents.SendEmailResetPasswordPage />}
        />
        <Route
          path="/reset-password"
          component={() => <LazyComponents.ResetPasswordPage />}
        />

        <Route
          path="/squadMember-fill-profile"
          component={() => <LazyComponents.FillProfileDataPage />}
        />

        <Route
          path="/tests-selection"
          component={() => (
            <LazyComponents.ProfileIntensifiersProvider>
              <LazyComponents.ProfileIntensifiersPage />
            </LazyComponents.ProfileIntensifiersProvider>
          )}
        />

        <Route
          path="/assessments-candidate/:userId?/:candidateId?"
          component={() => (
            <LazyComponents.OpenProfileIntensifiersProvider>
              <LazyComponents.OpenProfileIntensifiersPage />
            </LazyComponents.OpenProfileIntensifiersProvider>
          )}
        />

        <Route
          path="/assessments-candidate-thankyoupage/:userId?/:candidateId?"
          component={() => <LazyComponents.OpenTestsThankyouPage />}
        />

        <Route
          path={[
            '/tests-resumes/:userId?',
            '/assessments-candidate-resumes/:userId?/:candidateId?',
          ]}
          component={() => (
            <LazyComponents.UserTestResumesPage userRole={userRole} />
          )}
        />

        <Route
          path="/tests-softskills-questions"
          component={() => <LazyComponents.TestQuestionSkillsPage />}
        />
        {/* <Route
          path="/tests-personality-questions"
          component={() => <LazyComponents.TestPersonalityQuestionsPage />}
        /> */}
        <Route
          path={[
            '/tests-organizational-culture-questions',
            '/assessments-candidate-ocai/:userId/:candidateId',
          ]}
          component={() => (
            <LazyComponents.OCAIProviderMemberView>
              <LazyComponents.OCAIQuizPageMemberView />
            </LazyComponents.OCAIProviderMemberView>
          )}
        />

        <Route
          path="/tests-softskills-results"
          component={() => <LazyComponents.TestSoftSkillsResultPage />}
        />
        <Route
          path="/tests-personality-results"
          component={() => <LazyComponents.TestPersonalityResultPage />}
        />
        <Route
          path="/tests-organizational-culture-results"
          component={() => <LazyComponents.OCAIResultsPageMemberView />}
        />

        <Route
          path="/shared-personality-results/:userId"
          component={() => <LazyComponents.SharedPersonalityTestResults />}
        />
        <Route
          path="/shared-organizational-culture-results/:userId"
          component={() => <LazyComponents.SharedCultureTestResults />}
        />
        <Route
          path="/shared-softskills-results/:userId"
          component={() => <LazyComponents.SharedSkillsTestResults />}
        />

        <Route
          path="/tests-softskills-completed"
          component={() => <LazyComponents.TestCompletedSoftSkills />}
        />
        <Route
          path="/tests-personality-completed"
          component={() => <LazyComponents.TestCompletedPersonality />}
        />
        <Route
          path="/tests-organizational-culture-completed"
          component={() => <LazyComponents.OCAIThankYouPageMemberView />}
        />

        <Route
          exact
          path="/squadMember-profile"
          component={() => (
            <LazyComponents.ProfileMemberMainViewProvider>
              <LazyComponents.ProfileMemberMainView />
            </LazyComponents.ProfileMemberMainViewProvider>
          )}
        />

        <Route
          exact
          path="/profile-export/:userId?"
          component={() => (
            <LazyComponents.ProfileSquadMemberExportViewProvider>
              <LazyComponents.ProfileSquadMemberExportView
                userRole={userRole}
              />
            </LazyComponents.ProfileSquadMemberExportViewProvider>
          )}
        />

        <Route
          exact
          path="/squadMember-viewProfile/campaign/:squadMemberId"
          component={() => <LazyComponents.SquadMemberProfileDiscoveryView />}
        />
        <Route
          exact
          path="/squadmember-profile/hiring-process-view/:projectId/:positionIndex/:squadMemberId"
          component={() => (
            <LazyComponents.SquadMemberProfileHiringProcessProvider
              userRole={userRole}
            >
              <LazyComponents.SquadMemberProfileHiringProcessView />
            </LazyComponents.SquadMemberProfileHiringProcessProvider>
          )}
        />
        <Route
          exact
          path="/top-talent/:squadMemberId/:jobTitle?"
          component={() => <LazyComponents.SquadMemberProfileDiscoveryView />}
        />
        <Route
          path="/squadMember-viewProfile/:id"
          component={() => (
            <LazyComponents.ProfileMemberUserRolesView userRole={userRole} />
          )}
        />

        <Route
          path="/project-brief-selection/:campaign?"
          component={() => <LazyComponents.ProjectBriefSelectionPage />}
        />
        <Route
          path="/project-brief-onboarding/:campaign?"
          component={() => <LazyComponents.ProjectBriefOnboardingPage />}
        />
        <Route
          path="/project-brief-forms/:projectId?"
          component={() => <LazyComponents.ProjectBriefForms />}
        />
        <Route
          path="/upsell-thankyoupage"
          component={() => <LazyComponents.UpsellBriefingThankYouPage />}
        />
        <Route
          path="/project-brief-thanks-page"
          component={() => <LazyComponents.FormsThanksPage />}
        />
        <Route
          path="/project-brief-campaign-thanks-page"
          component={() => <LazyComponents.CampaignFormsThanksPage />}
        />
        <Route
          path="/project-brief-searching-matchs-page"
          component={() => <LazyComponents.MatchSearchingPage />}
        />
        <Route
          path="/project-brief-matchs-page"
          component={() => <LazyComponents.MatchPage />}
        />

        <Route
          path="/project-brief-view/:id/:positionIndex?"
          component={() => <LazyComponents.ProjectBriefView />}
        />

        <Route
          path="/project-brief-squad-approved-thanks-page"
          component={() => <LazyComponents.BriefSquadThanksPage isApproved />}
        />
        <Route
          path="/project-brief-squad-desapproved-thanks-page"
          component={() => <LazyComponents.BriefSquadThanksPage />}
        />
        <Route
          path="/project-brief-blocked-page"
          component={() => <LazyComponents.BlockedPage />}
        />

        <Route
          path="/inhouse-first-member-budget-send/:projectId"
          component={() => <LazyComponents.FirstMemberBudgetSendView />}
        />
        <Route
          path="/inhouse-budget/:projectId/:positionIndex?"
          component={() => <LazyComponents.ProposalBudgetView />}
        />
        <Route
          path="/inhouse-budget-thankyoupage/:pagetype/:viewtype"
          component={() => <LazyComponents.BudgetThankYouPage />}
        />

        <Route
          path="/squadOwner-register/:campaign?"
          component={() => <LazyComponents.SquadOwnerRegister />}
        />
        <Route
          path="/squadOwner-profile"
          component={() => <LazyComponents.ProfileSquadOwner />}
        />
        <Route
          path="/squadOwner-viewProfile/:id"
          component={() => <LazyComponents.ProfileSquadOwnerOpenView />}
        />

        <Route
          path="/cms-selection"
          component={() => <LazyComponents.CMSForkPage />}
        />

        <Route
          path="/cms-professionals"
          component={() => <LazyComponents.SearchProfessionalsPage />}
        />
        <Route
          path="/cms-owners"
          component={() => <LazyComponents.SearchOwnersPage />}
        />

        <Route
          path="/cms-projects"
          component={() => <LazyComponents.SearchProjectsPage />}
        />

        <Route
          path="/cms-squad-selection/:id"
          component={() => (
            <LazyComponents.SquadListProvider>
              <LazyComponents.SquadSelectionPage />
            </LazyComponents.SquadListProvider>
          )}
        />

        <Route
          path="/cms-squad-suggestion/:projectId"
          component={() => (
            <LazyComponents.CmsSquadSuggestionProvider>
              <LazyComponents.SuggestionCMSPage />
            </LazyComponents.CmsSquadSuggestionProvider>
          )}
        />

        <Route
          path="/cms-match-rating"
          component={() => <LazyComponents.MatchRatingPage />}
        />

        <Route
          path="/allocate-hours"
          component={() => (
            <LazyComponents.AllocateHoursProvider>
              <LazyComponents.AllocateHourPage />
            </LazyComponents.AllocateHoursProvider>
          )}
        />
        <Route
          path="/allocate-hours-thankyoupage"
          component={() => (
            <LazyComponents.AllocateHoursProvider>
              <LazyComponents.AllocateHoursThankYouPage />
            </LazyComponents.AllocateHoursProvider>
          )}
        />

        <Route
          path="/cms-analytics"
          component={() => <LazyComponents.AnalyticsDataStudioPage />}
        />

        <Route
          path="/signup-admin"
          component={() => <LazyComponents.AdminSignUpPage />}
        />

        <Route
          path="/forbidden"
          component={() => <LazyComponents.PageForbidden />}
        />
        <Route
          path="/maintenance"
          component={() => <LazyComponents.MaintenancePage />}
        />
        <Route
          path="/teste"
          component={() => <LazyComponents.CreateComponent />}
        />

        <Route
          exact
          path="/jobs"
          component={() => (
            <LazyComponents.JobsOpeningsProvider>
              <LazyComponents.JobsOpeningsView />
            </LazyComponents.JobsOpeningsProvider>
          )}
        />

        <Route
          exact
          path="/jobs/details-view/:projectId/:positionIndex/:upsellOrReplacementIdQuery?"
          component={() => (
            <LazyComponents.JobDetailsProvider>
              <LazyComponents.JobDetailsView userRole={userRole} />
            </LazyComponents.JobDetailsProvider>
          )}
        />

        <Route
          path="/support-squadOwner-view"
          component={() => (
            <LazyComponents.SupportOwnerViewProvider>
              <LazyComponents.SupportOwnerViewPage />
            </LazyComponents.SupportOwnerViewProvider>
          )}
        />
        <Route
          path="/support-squadMember-view"
          component={() => (
            <LazyComponents.SupportMemberViewProvider>
              <LazyComponents.SupportMemberViewPage />
            </LazyComponents.SupportMemberViewProvider>
          )}
        />

        <Route
          path="/credits-and-payments/:squadOwnerId?"
          component={() => (
            <LazyComponents.CreditsPaymentsProvider>
              <LazyComponents.CreditsPaymentsPage userRole={userRole} />
            </LazyComponents.CreditsPaymentsProvider>
          )}
        />
        <Route
          path="/credits"
          component={() => (
            <LazyComponents.CreditsProvider>
              <LazyComponents.CreditsPage />
            </LazyComponents.CreditsProvider>
          )}
        />
        <Route
          path="/credits-thankyoupage"
          component={() => (
            <LazyComponents.CreditsProvider isThankYouPage>
              <LazyComponents.CreditsThankYouPage />
            </LazyComponents.CreditsProvider>
          )}
        />

        <Route
          path="/extracts"
          component={() => (
            <LazyComponents.OwnerExtractProvider>
              <LazyComponents.OwnerExtractsPage />
            </LazyComponents.OwnerExtractProvider>
          )}
        />

        <Route
          exact
          path={'/matchmaking'}
          component={() => (
            <LazyComponents.MatchMakingProvider>
              <LazyComponents.MatchMakingPage />
            </LazyComponents.MatchMakingProvider>
          )}
        />

        <Route
          exact
          path={'/matchmaking/create-persona-view/:projectId?'}
          component={() => (
            <LazyComponents.MatchMakingCreatePersonaProvider
              userRole={userRole}
            >
              <LazyComponents.MatchMakingCreatePersonaViewPage />
            </LazyComponents.MatchMakingCreatePersonaProvider>
          )}
        />

        <Route
          exact
          path={'/matchmaking/thankyoupage'}
          component={() => <LazyComponents.MatchmakingThankyouPageView />}
        />

        <Route
          exact
          path={'/matchmaking/:matchmakingId'}
          component={() => (
            <LazyComponents.MatchMakingInternalViewProvider>
              <LazyComponents.MatchMakingInternalViewPage />
            </LazyComponents.MatchMakingInternalViewProvider>
          )}
        />

        <Route
          exact
          path={'/matchmaking/results-view/:matchmakingId/:userId'}
          component={() => (
            <LazyComponents.MatchMakingResultsViewProvider>
              <LazyComponents.MatchMakingResultsViewPage />
            </LazyComponents.MatchMakingResultsViewProvider>
          )}
        />

        <Route
          exact
          path={'/assessments-candidate-match-results/:matchmakingId/:userId'}
          component={() => (
            <LazyComponents.OpenMatchMakingResultsViewProvider>
              <LazyComponents.OpenMatchMakingResultsViewPage />
            </LazyComponents.OpenMatchMakingResultsViewProvider>
          )}
        />

        <Route
          exact
          path={'/matchmaking/invite-view/:matchmakingId/:invitationId'}
          component={() => (
            <LazyComponents.MatchMakingInviteViewProvider>
              <LazyComponents.MatchMakingInviteViewPage />
            </LazyComponents.MatchMakingInviteViewProvider>
          )}
        />

        <Route
          exact
          path={['/cms/persona/create-view', '/company/persona/create-view']}
          component={() => <LazyComponents.HunterHeroPersonaFormPage />}
        />

        <Route
          exact
          path={[
            '/cms/persona/:matchmakingId',
            '/company/persona/:matchmakingId',
          ]}
          component={() => <LazyComponents.HunterHeroPersonaPage />}
        />

        <Route
          exact
          path={'/persona/:personaId'}
          component={() => <LazyComponents.ResultsPersonaView />}
        />

        {/* <Route
            path="/onboarding/:projectId"
            component={() => (
              <OnboardingProvider>
                <OnboardingPage />
              </OnboardingProvider>
            )}
          /> */}

        {/* <Route
            path="/my-onboardings"
            component={() => (
              <MyOnboardingsProvider>
                <MyOnboardingsPage />
              </MyOnboardingsProvider>
            )}
          /> */}

        <Route
          path="/extract-payments/:squadOwnerId?"
          component={() => (
            <LazyComponents.ExtractPaymentsProvider>
              <LazyComponents.ExtractPaymentsPage />
            </LazyComponents.ExtractPaymentsProvider>
          )}
        />

        <Route
          exact
          path="/my-projects/:squadOwnerId?"
          component={() => (
            <LazyComponents.MyProjectsView userRole={userRole} />
          )}
        />
        <Route
          exact
          path="/my-projects/:projectView/:projectId"
          component={() => <LazyComponents.ProjectView userRole={userRole} />}
        />

        <Route
          exact
          path="/my-freelancers"
          component={() => <LazyComponents.MyFreelancersView />}
        />
        <Route
          exact
          path="/my-freelancers/:freelancerView?/:projectId?/:freelancerId?"
          component={() => (
            <LazyComponents.FreelancerView userRole={userRole} />
          )}
        />

        <Route
          path="/dashboard/:squadOwnerId?"
          component={() => <LazyComponents.DashboardPage userRole={userRole} />}
        />

        <Route
          exact
          path="/workspace"
          component={() => (
            <LazyComponents.WorkspaceProvider workspaceId={workspaceId}>
              <LazyComponents.WorkspacePage />
            </LazyComponents.WorkspaceProvider>
          )}
        />

        <Route
          exact
          path="/workspace/invite-view/:invitationId/:workspaceId"
          component={() => (
            <LazyComponents.WorkspaceInviteProvider workspaceId={workspaceId}>
              <LazyComponents.WorkspaceInvitePage />
            </LazyComponents.WorkspaceInviteProvider>
          )}
        />

        <Route
          exact
          path="/task-assignments"
          component={() => (
            <LazyComponents.TaskAssignmentsProvider userRole={userRole}>
              <LazyComponents.TaskPanelView userRole={userRole} />
            </LazyComponents.TaskAssignmentsProvider>
          )}
        />

        <Route
          exact
          path="/task-assignments/kanban-view/:projectId"
          component={() => (
            <LazyComponents.KanbanViewProvider userRole={userRole}>
              <LazyComponents.KanbanView userRole={userRole} />
            </LazyComponents.KanbanViewProvider>
          )}
        />

        <Route
          exact
          path="/task-assignments/timesheet-view/:projectId"
          component={() => <LazyComponents.TimesheetView userRole={userRole} />}
        />

        <Route
          path="/new-journey"
          component={() => (
            <LazyComponents.SaveProjectProvider>
              <LazyComponents.NewJourney />
            </LazyComponents.SaveProjectProvider>
          )}
        />

        <Route
          exact
          path={[
            '/quiz-personality',
            '/assessments-candidate-quiz-personality/:userId?/:candidateId?',
          ]}
          component={() => (
            <LazyComponents.DISCViewProvider>
              <LazyComponents.DISCQuizView />
            </LazyComponents.DISCViewProvider>
          )}
        />

        <Route
          exact
          path="/ocai-organizational-culture-tool"
          component={() => (
            <LazyComponents.OCAIProviderOwnerView>
              <LazyComponents.OCAIQuizPageOwnerView />
            </LazyComponents.OCAIProviderOwnerView>
          )}
        />

        <Route
          exact
          path="/ocai-complementary"
          component={() => (
            <LazyComponents.OCAIComplementaryProviderOwnerView>
              <LazyComponents.OCAIComplementaryQuizPageOwnerView />
            </LazyComponents.OCAIComplementaryProviderOwnerView>
          )}
        />

        <Route
          exact
          path="/company-ocai-complementary/:companyId"
          component={() => (
            <LazyComponents.HunterHeroCompanyOCAIComplementaryFormProvider>
              <LazyComponents.HunterHeroCompanyOCAIComplementaryFormPage />
            </LazyComponents.HunterHeroCompanyOCAIComplementaryFormProvider>
          )}
        />

        <Route
          exact
          path="/company-ocai-complementary/results/:companyId"
          component={() => (
            <LazyComponents.HunterHeroCompanyOCAIResultsProvider>
              <LazyComponents.HunterHeroCompanyOCAIResultsPage />
            </LazyComponents.HunterHeroCompanyOCAIResultsProvider>
          )}
        />

        <Route
          exact
          path="/ocai-complementary/results/:userId?"
          component={() => (
            <LazyComponents.OCAIComplementaryProviderResultsPageOwnerView>
              <LazyComponents.OCAIComplementaryResultsPageOwnerView />
            </LazyComponents.OCAIComplementaryProviderResultsPageOwnerView>
          )}
        />

        <Route
          exact
          path={[
            '/quiz-moving-motivators',
            '/assessments-candidate-quiz-moving-motivators/:userId?/:candidateId?',
          ]}
          component={() => (
            <LazyComponents.MovingMotivatorsProvider>
              <LazyComponents.MovingMotivatorsPage userRole={userRole} />
            </LazyComponents.MovingMotivatorsProvider>
          )}
        />

        <Route
          exact
          path={'/quiz-moving-motivators/results-view/:userId?'}
          component={() => (
            <LazyComponents.MovingMotivatorsResultsProvider userRole={userRole}>
              <LazyComponents.MovingMotivatorsResultsPage />
            </LazyComponents.MovingMotivatorsResultsProvider>
          )}
        />

        <Route
          exact
          path={'/shared-moving-motivators/results-view/:userId?'}
          component={() => (
            <LazyComponents.MovingMotivatorsSharedResultsProvider>
              <LazyComponents.MovingMotivatorsSharedResultsPage />
            </LazyComponents.MovingMotivatorsSharedResultsProvider>
          )}
        />

        <Route
          exact
          path="/applications"
          component={() => (
            <LazyComponents.ApplicationsMemberProvider>
              <LazyComponents.ApplicationsMemberPage />
            </LazyComponents.ApplicationsMemberProvider>
          )}
        />

        <Route
          exact
          path="/recommender-evaluation/:userId/:recommendationRecordId"
          component={() => <LazyComponents.RecommenderEvaluationPage />}
        />

        <Route
          exact
          path="/user-recommendations/:userId"
          component={() => <LazyComponents.UsersRecommendationsPage />}
        />

        <Route
          exact
          path="/assessments"
          component={() => (
            <LazyComponents.AssessmentsProvider>
              <LazyComponents.AssessmentsPage />
            </LazyComponents.AssessmentsProvider>
          )}
        />

        <Route
          exact
          path="/assessments/invite-view/:assessmentsId/:invitationId"
          component={() => (
            <LazyComponents.AssessmentsInviteViewProvider>
              <LazyComponents.AssessmentsInviteView />
            </LazyComponents.AssessmentsInviteViewProvider>
          )}
        />

        <Route
          exact
          path="/assessments/thankyoupage"
          component={() => <LazyComponents.AssessmentsInviteThankyouPage />}
        />

        <Route path="*" component={() => <LazyComponents.Page404 />} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
